<div class="MainContainer">
  <div class="row">
    <app-tabs
      [activeTab]="currentPathIndex"
      (tabSelectionChange)="onTabSelectionChange($event)"
      contentContainerPadding="24px"
      [isSettingsPage]="false"
    >
      <!--Overview-->
      <app-tab tabName="Overview">
        <app-overview *ngIf="this.selectedTab == 'Overview'"></app-overview>
      </app-tab>
      <!--Tracking-->
      <app-tab tabName="Tracking">
        <app-tracking *ngIf="this.selectedTab == 'Tracking'"></app-tracking>
      </app-tab>
      <!--Telematics-Map-->
      <app-tab
        tabName="Telematics"
        id="Telematics"
      >
        <app-telematics-map
          [RideData]="rideData"
          *ngIf="this.selectedTab == 'Telematics'"
        ></app-telematics-map>
      </app-tab>
    </app-tabs>
  </div>
</div>
