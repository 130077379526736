<app-about-dialog></app-about-dialog>
Test
<nav class="navbar">
  <div class="main-container">
    <div class="left-container">
      <div class="head-line-container">
        <button
          class="btn btn-link"
          (click)="toggleSidebar()"
          (mouseenter)="isHovering = true"
          (mouseleave)="isHovering = false"
        >
          <svg-icon
            src="assets/images/icons/{{ getSidebarIcon() }}.svg"
            [svgStyle]="{ 'width.px': 36 }"
          ></svg-icon>
        </button>

        <div
          class="active-page-name"
          [ngClass]="{
            'custom-font-size':
              (currentMenu || {}).title === 'Drivers & Vehicles'
          }"
        >
          {{ (currentMenu || {}).title || "Home" }}
        </div>
      </div>
      <div class="navbar-expand-md navbar-light bg-light cta-navbar">
        <div
          class="navbar-collapse"
          [class.collapse]="collapsed"
          id="navbarContent"
        >
          <ul class="navbar-nav ms-auto">
            <li
              [routerLinkActive]="['active']"
              class="nav-item"
              ngbDropdown
              *ngIf="
                currentMenu &&
                !currentMenu.children &&
                currentMenu.title !== 'Home'
              "
            >
              <a
                class="nav-link"
                tabindex="0"
                role="button"
                [routerLink]="currentMenu.url"
              >
                {{ currentMenu.title }}
              </a>
            </li>
            <ng-container *ngFor="let item of currentMenu?.children">
              <li
                ngbDropdown
                [routerLinkActive]="['active']"
                class="nav-item"
                [ngClass]="{
                  active:
                    this.currentPath.includes('-district') &&
                    item.url === 'districts'
                }"
              >
                <a
                  class="nav-link"
                  tabindex="0"
                  *ngIf="!item.children?.length"
                  role="button"
                  [routerLink]="item.url"
                >
                  {{ item.title }}
                </a>
                <a
                  class="nav-link"
                  tabindex="0"
                  *ngIf="item.children?.length"
                  ngbDropdownToggle
                  role="button"
                >
                  {{ item.title }}
                </a>
                <div
                  ngbDropdownMenu
                  class="sub-links-ddl"
                >
                  <a
                    ngbDropdownItem
                    [routerLink]="subChildItem.url"
                    *ngFor="let subChildItem of item.children"
                  >
                    {{ subChildItem.title }}
                  </a>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

    <div class="right-container">
      <div class="burger-button">
        <svg-icon
          src="assets/images/icons/burger.svg"
          [svgStyle]="{ 'width.px': 24 }"
        ></svg-icon>
      </div>
      <div class="right-content">
        <div class="time-container">
          <app-current-date-time></app-current-date-time>
        </div>
        <div class="icons-container">
          <div class="avatarContainer">
            <div
              ngbDropdown
              display="dynamic"
              placement="bottom-end"
            >
              <button
                type="button"
                tabindex="0"
                aria-label="open user menu"
                class="btn-user-avatar"
                ngbDropdownToggle
              >
                <img
                  src="assets/images/userAvatar.png"
                  alt="user avatar"
                  title="User Avatar Icon"
                  width="17"
                  height="17"
                />
              </button>
              <div
                ngbDropdownMenu
                class="user-avtion-ngbDropdownMenu"
              >
                <button
                  ngbDropdownItem
                  class="btn-user-action active"
                  (click)="openAbout()"
                >
                  <svg-icon
                    src="assets/images/icons/About.svg"
                    [svgStyle]="{ 'width.px': 18, 'height.px': 18 }"
                  ></svg-icon>
                  About
                </button>
                <button
                  ngbDropdownItem
                  class="btn-user-action logout-btn"
                  (click)="Logout()"
                >
                  <svg-icon
                    src="assets/images/icons/logout.svg"
                    [svgStyle]="{ 'width.px': 18, 'height.px': 18 }"
                  ></svg-icon>
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
