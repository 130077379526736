<div>
  <div class="row">
    <form
      [formGroup]="TelematicsForm"
      (keydown.enter)="$event.preventDefault()"
    >
      <div class="d-flex justify-content-left flex-sm-column flex-lg-row">
        <div class="input-wrapper">
          <!--Date of Service-->
          <kendo-datepicker
            class="datepicker-custom-field"
            formControlName="dateOfService"
            placeholder="Date of Service"
            [format]="format"
            [popupSettings]="{ popupClass: 'datepicker_custom_popup' }"
            (valueChange)="onDateChange($event)"
            [disabledDates]="disabledDates"
          ></kendo-datepicker>
        </div>
        <!--Drivers-->
        <div class="input-wrapper">
          <kendo-dropdownlist
            #dropdownlistDriverName
            formControlName="driverId"
            [popupSettings]="{ popupClass: 'dropDown_custom_popup' }"
            [data]="driversList"
            [kendoDropDownFilter]="filterSettings"
            textField="fullName"
            valueField="id"
            name="driverId"
            id="driverId"
            [valuePrimitive]="true"
            [defaultItem]="{
              fullName: 'Driver Name',
              value: null,
              disable: true
            }"
            [itemDisabled]="itemDisabled"
            class="dropDown_custom_field"
            [class.ng-dirty]="changeColor"
            (valueChange)="getRidesByDriver($event)"
          >
          </kendo-dropdownlist>
        </div>

        <!--Ride-->
        <div class="input-wrapper">
          <kendo-dropdownlist
            #dropdownlistRideId
            formControlName="rideId"
            [popupSettings]="{ popupClass: 'dropDown_custom_popup' }"
            [data]="rideList"
            [kendoDropDownFilter]="filterSettings"
            textField="id"
            valueField="id"
            name="rideId"
            id="rideId"
            [valuePrimitive]="true"
            [defaultItem]="{
              id: 'Ride ID',
              value: null,
              disable: true
            }"
            [itemDisabled]="itemDisabled"
            class="dropDown_custom_field"
            [class.ng-dirty]="changeColor"
            (valueChange)="triggerChange()"
          >
          </kendo-dropdownlist>
        </div>

        <!--Search & Clear button-->
        <div class="col d-flex justify-content-end align-items-start">
          <div class="buttonsContainer">
            <app-button
              class="optional"
              sizeClass="xs"
              [disabled]="false"
              (onClick)="Clear()"
              >Clear</app-button
            >
            <app-button
              [disabled]="!TelematicsForm.valid"
              class="light"
              sizeClass="xs"
              (onClick)="Search()"
              >Search</app-button
            >
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="card">
    <div class="row m-0">
      <div
        class="col-lg-8 col-md-8"
        style="padding-left: 0px"
      >
        <app-telematics-section-map
          #TelematicsSectionMap
          *ngIf="!isShow"
        ></app-telematics-section-map>
        <div *ngIf="isShow">
          <div class="my-google-map">
            <div class="map-container">
              <google-map [options]="defaultMapOptions"></google-map>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-4"
        *ngIf="isShow"
      >
        <div class="h-100 d-flex justify-content-center align-items-center">
          <div class="text-center">
            <img
              alt="no-map-available"
              src="assets/images/icons/icon-no-map-available.png"
            />
            <div
              style="
                width: 320px;
                text-align: center;
                color: #94a3b8;
                font-size: 13px;
                font-weight: 400;
                line-height: 23px;
                word-wrap: break-word;
              "
              class="text-center"
            >
              Please enter Ride ID &amp; click on search to retrieve the
              telematics that are related to that ride.
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4">
        <app-telematics-section-details
          *ngIf="!isShow"
          [RideDetail]="rideDetailsResult"
        ></app-telematics-section-details>
      </div>
    </div>
  </div>
</div>
