import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as echarts from 'echarts';
import { map } from 'rxjs';
import { AppComponent } from '../../../app.component';
import {
  EventStatistic,
  EventStatisticData,
} from '../../../interfaces/ride-data/event-statistic.interface';
import { RidesService } from '../../../services/rides/rides-list.service';
type EChartsOption = echarts.EChartsOption;

@Component({
  selector: 'app-bar-stack-normalization-chart',
  templateUrl: './bar-stack-normalization-chart.component.html',
  styleUrls: ['./bar-stack-normalization-chart.component.css'],
})
export class BarStackNormalizationChartComponent implements OnInit {
  constructor(
    private _ridesService: RidesService,
    private _appComponent: AppComponent,
  ) {}

  ngOnInit(): void {
    this.getEventsStatistics();
  }

  getEventsStatistics() {
    this._appComponent.loaderComponent.toggleLoaderVisibility();
    this._ridesService
      .getEventsStatistics()
      .pipe(map((res: EventStatistic) => res))
      .subscribe({
        next: (response: EventStatisticData) => {
          let rawData: Array<any> = [];

          const eventTypesData = [];
          eventTypesData.push({ Text: 'Phone Used', Value: 1 });
          eventTypesData.push({ Text: 'Acceleration', Value: 2 });
          eventTypesData.push({ Text: 'Speed Limit', Value: 3 });
          eventTypesData.push({ Text: 'Hard Braking', Value: 4 });
          eventTypesData.push({ Text: 'Hard Return', Value: 5 });

          let allDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
          for (let event of eventTypesData) {
            let eventArray: Array<any> = [];
            for (let day of allDays) {
              let value = response.data
                .filter(
                  a => a.dateOfService == day && a.eventTypeId == event.Value,
                )
                .map(a => a.count)[0];
              if (value) {
                eventArray.push(value);
              } else {
                eventArray.push(0);
              }
            }
            rawData.push(eventArray);
          }

          //Events Types
          let chartDom = document.getElementById(
            'bar-stack-normalization-chart',
          );

          let myChart = echarts.init(chartDom);
          let option: EChartsOption;

          const totalData: any = [];
          for (let i = 0; i < rawData[0].length; ++i) {
            let sum = 0;
            for (const element of rawData) {
              sum += element[i];
            }
            totalData.push(sum);
          }
          const grid = {
            left: 100,
            right: 100,
            top: 50,
            bottom: 50,
          };
          const gridWidth = myChart.getWidth() - grid.left - grid.right;
          const gridHeight = myChart.getHeight() - grid.top - grid.bottom;
          const categoryWidth = gridWidth / rawData[0].length;
          const barWidth = categoryWidth * 0.6;
          const barPadding = (categoryWidth - barWidth) / 2;
          const series: any = [
            'Phone Used',
            'Acceleration',
            'Speed Limit',
            'Hard Braking',
            'Hard Return',
          ].map((name, sid) => {
            return {
              name,
              type: 'bar',
              stack: 'total',
              barWidth: '60%',
              label: {
                show: true,
                formatter: (params: any) => params.value,
              },
              data: rawData[sid].map((d: any, did: any) => totalData[did]),
            };
          });
          const color = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de'];
          const elements = [];
          for (let j = 1, jlen = rawData[0].length; j < jlen; ++j) {
            const leftX = grid.left + categoryWidth * j - barPadding;
            const rightX = leftX + barPadding * 2;
            let leftY = grid.top + gridHeight;
            let rightY = leftY;
            for (let i = 0, len = series.length; i < len; ++i) {
              const points = [];
              const leftBarHeight =
                (rawData[i][j - 1] / totalData[j - 1]) * gridHeight;
              points.push([leftX, leftY]);
              points.push([leftX, leftY - leftBarHeight]);
              const rightBarHeight =
                (rawData[i][j] / totalData[j]) * gridHeight;
              points.push([rightX, rightY - rightBarHeight]);
              points.push([rightX, rightY]);
              points.push([leftX, leftY]);
              leftY -= leftBarHeight;
              rightY -= rightBarHeight;
              elements.push({
                type: 'polygon',
                shape: {
                  points,
                },
                style: {
                  fill: color[i],
                  opacity: 0.25,
                },
              });
            }
          }
          option = {
            legend: {
              selectedMode: false,
            },
            grid,
            yAxis: {
              type: 'value',
            },
            xAxis: {
              type: 'category',
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            },
            series,
            graphic: {
              elements,
            },
          };

          option && myChart.setOption(option);
        },
        error: (error: HttpErrorResponse) => {
          this._appComponent.loaderComponent.toggleLoaderVisibility();
        },
        complete: () => {
          this._appComponent.loaderComponent.toggleLoaderVisibility();
        },
      });
  }
}
