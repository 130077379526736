<div
  class="inputWrapper"
  [ngClass]="{ hasText: bindingValue !== '' }"
  [ngClass]="{ isInvalid: invalid }"
>
  <div
    class="iconWrapper"
    *ngIf="icon"
  >
    <svg-icon
      class="searchIcon"
      src="assets/images/icons/{{ icon }}.svg"
      [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
    ></svg-icon>
    <div class="inputLine"></div>
  </div>
  <input
    id="{{ id }}"
    placeholder="{{ placeholder }}"
    class="fieldInput"
    [(ngModel)]="bindingValue"
    (ngModelChange)="onInputChange($event)"
  />
  <svg-icon
    *ngIf="invalid"
    class="infoIcon"
    src="assets/images/icons/info.svg"
    [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
  ></svg-icon>
</div>
