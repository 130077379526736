<div
  [ngStyle]="{ width: width }"
  class="d-flex flex-column flex-shrink-0 bg-body-tertiary sidebar"
  style="position: fixed"
>
  <div class="mainSidebar">
    <ul class="cui-menu-left-list cui-menu-left-list-root no-print menu-list">
      <li
        class="menu-item"
        [ngClass]="item.children?.length ? 'hasMenu' : ''"
        *ngFor="let item of MainMenu"
      >
        <a
          class="main-menu-link"
          [routerLink]="item.url"
          (click)="reflectHeaderMenu(item, item.url)"
        >
          <span class="flux-micon">
            <svg-icon
              src="assets/images/icons/{{ item.icon }}.svg"
              [svgStyle]="{ 'width.px': 24 }"
            ></svg-icon>
          </span>
          <span class="flux-mtext text"> {{ item.title }}</span>
          <span class="arrow-icon"></span>
        </a>
      </li>
      <li
        class="side-menu-hr"
        *ngIf="SystemMenu.length > 0"
      >
        <hr />
        <span
          class="sidebar-text {{ width == '64px' ? 'small-sidebar-text' : '' }}"
          >{{ width != "64px" ? "SYSTEM" : "SYSTEM" }}</span
        >
      </li>
      <li
        class="menu-item"
        (mouseover)="openOverlay(item.children?.length)"
        (mouseout)="closeOverlay()"
        [ngClass]="item.children?.length ? 'hasMenu' : ''"
        *ngFor="let item of SystemMenu"
      >
        <a
          class="main-menu-link"
          *ngIf="item.url == ''"
        >
          <span class="flux-micon">
            <svg-icon
              src="assets/images/icons/{{ item.icon }}.svg"
              [svgStyle]="{ 'width.px': 24 }"
            ></svg-icon>
          </span>
          <span class="flux-mtext text"> {{ item.title }}</span>
          <span class="arrow-icon"></span>
        </a>
        <a
          class="main-menu-link"
          *ngIf="item.url != ''"
          [routerLink]="item.url"
          (click)="reflectHeaderMenu(item, item.url)"
        >
          <span class="flux-micon">
            <svg-icon
              src="assets/images/icons/{{ item.icon }}.svg"
              [svgStyle]="{ 'width.px': 24 }"
            ></svg-icon>
          </span>
          <span class="flux-mtext text"> {{ item.title }}</span>
          <span class="arrow-icon"></span>
        </a>
        <ul *ngIf="item.children?.length">
          <li
            class="menu-li menu-item"
            *ngFor="let subItem of item.children"
          >
            <a
              class="subMenu-link"
              *ngIf="subItem.children?.length"
              (click)="reflectHeaderMenu(item, subItem.url)"
            >
              <span class="flux-mtext">{{ subItem.title }}</span>
              <span class="flux-mcaret subMenuNotSelected"></span>
            </a>
            <a
              class="subMenu-link"
              [routerLink]="subItem.url"
              *ngIf="!subItem.children?.length"
              (click)="reflectHeaderMenu(item, subItem.url)"
            >
              <span class="flux-mtext">{{ subItem.title }}</span>
              <span class="flux-mcaret"></span>
            </a>
            <ul
              class="child-submenu"
              style="display: none"
              *ngIf="subItem.children?.length"
            >
              <li *ngFor="let childrenItem of subItem.children">
                <a
                  [routerLink]="childrenItem.url"
                  class="child-subMenu-link"
                  (click)="reflectHeaderMenu(item, childrenItem.url)"
                >
                  <span class="dot-micon"> </span>
                  {{ childrenItem.title }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li
        class="side-menu-hr"
        *ngIf="appTools.length > 0"
      >
        <hr />
        <span class="sidebar-text sidebar-text-tools">APPS</span>
      </li>
    </ul>
  </div>
</div>

<div
  class="sideMenu-overlay"
  *ngIf="isSidebarExpanded"
></div>
