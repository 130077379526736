// Angular Core Modules
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Interfaces
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { FilterDescriptor, State } from '@progress/kendo-data-query';
import {
  GridApiParamsData,
  GridDataSchema,
  GridTriggeredActionData,
} from 'src/app/interfaces/shared/grid-data/grid-data.interface';

// Modules
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import {
  DataStateChangeEvent,
  ExcelModule,
  GridDataResult,
  GridModule,
  PagerSettings,
  PageSizeItem,
  PDFModule,
} from '@progress/kendo-angular-grid';
import { map } from 'rxjs';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import {
  DriversList,
  DriversListData,
} from '../../../interfaces/driver-data/driver-list.interface';
import {
  Ride,
  RideList,
  RidesListData,
} from '../../../interfaces/ride-data/ride-list.interface';
import { DriversService } from '../../../services/drivers/drivers-list.service';
import { RideNavigationService } from '../../../services/rides/ride-navigation.service';
import { RidesService } from '../../../services/rides/rides-list.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-grid-template',
  templateUrl: './app-grid-template.component.html',
  styleUrls: ['./app-grid-template.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    GridModule,
    ExcelModule,
    PDFModule,
    RouterModule,
    FormsModule,
    ComponentsModule,
    NgbModule,
  ],
})
export class GridTemplateComponent {
  @Input() pageName: string = '';
  @Input() gridData: GridDataResult = { data: [], total: 0 };
  @Input() gridSchema: GridDataSchema;
  @Input() pagerSettings?: PagerSettings | null = null;
  @Input() actionsColumnWidth: number = 100;
  @Input() excelExportFunction: Function;

  @Output() triggerExportToPDF: EventEmitter<string[]> = new EventEmitter();
  @Output() triggerGetGridDataAPI: EventEmitter<GridApiParamsData> =
    new EventEmitter();
  @Output() triggerActionEvent: EventEmitter<GridTriggeredActionData> =
    new EventEmitter();

  private currentDate: Date = new Date();
  public formattedSelectedDate?: Date;
  public disabelSearch :boolean=true;
  public disabledDates = (date: Date): boolean => {
    return date > this.currentDate;
  };
  public driverId?: number;
  public rideId?: number;

  public driversList: DriversList[] = [];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'startsWith',
  };
  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return itemArgs.dataItem.disable;
  }
  public rideList: RideList[] = [];

  public defaultPagerSettings: PagerSettings = {
    buttonCount: 5,
    info: true,
    pageSizes: true,
    previousNext: true,
    type: 'numeric',
    position: 'bottom',
  };
  public searchQuery: string = '';
  public state: State = {
    skip: 0,
    take: 25,
    group: [],
    filter: { filters: [], logic: 'or' },
    sort: [],
  };
  public pageSizes: PageSizeItem[] = [
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    { text: '250', value: 250 },
    { text: '500', value: 500 },
    { text: '1000', value: 1000 },
    {
      text: 'All',
      value: 1,
    },
  ];
  public selectedRows: string[] = [];
  public columnSearchFilterTimeout: NodeJS.Timeout;
  public recordLabel: string = 'Record';
  public filtersList: FilterDescriptor[] = [];

  constructor(
    private _driversService: DriversService,
    private _ridesService: RidesService,
    private _rideNavigationService: RideNavigationService,
    private _appComponent: AppComponent,
  ) {
    this.getDriverList();
  }

  ngOnChanges() {
    if ((this.gridSchema.cols || []).length && this.filtersList.length === 0) {
      this.initializeGridFilters();
    }

    if (this.gridData.total) {
      this.updatePageSizesList();
    }
  }

  private initializeGridFilters(): void {
    this.gridSchema.cols.forEach(col => {
      this.filtersList.push({
        field: col.key,
        operator: 'contains',
        value: '',
      });
    });
  }

  private updatePageSizesList(): void {
    const allRecordsItemRef = this.pageSizes.find(size => size.text === 'All');
    allRecordsItemRef!.value = this.gridData.total;

    this.recordLabel = this.gridData.total > 1 ? 'Records' : 'Record';
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.selectedRows = [];
    this.triggerGridDataAPI();
  }

  public clearMySelection(): void {
    this.selectedRows = [];
  }

  public filterAmongColumns(input: Event): void {
    clearTimeout(this.columnSearchFilterTimeout);

    this.columnSearchFilterTimeout = setTimeout(() => {
      this.searchQuery = (input.target as HTMLInputElement).value;
      this.state.skip = 0;
    }, 750);
  }

  public generateGridPDF() {
    this.triggerExportToPDF.emit(this.selectedRows);
  }

  public clearColumnFilter(fieldName: string): void {
    const detectedFilter = this.filtersList.find(
      filter => filter.field === fieldName,
    );

    if (detectedFilter) {
      detectedFilter.value = '';
      detectedFilter.operator = 'contains';
    }

    const filteredRecords = (
      this.state.filter?.filters as FilterDescriptor[]
    ).filter(row => row.field !== fieldName);

    this.state.filter!.filters = filteredRecords;
    this.state.skip = 0;

    this.dataStateChange(this.state as DataStateChangeEvent);
  }

  public checkColumnFilter(columnName: string): boolean {
    const filters: FilterDescriptor[] | undefined = this.state.filter
      ?.filters as FilterDescriptor[];

    if (filters && filters.length > 0) {
      const matchingFilter = filters.find(
        filter => filter.field === columnName,
      );
      return !!matchingFilter; // Double negation to convert to boolean
    }

    return false; // No matching filter found
  }

  public getFilterValue(selectedFilter: FilterDescriptor) {
    const detectedFilter = this.filtersList.find(
      filter => filter.field === selectedFilter.field,
    );

    if (detectedFilter) this.state.filter?.filters.push(detectedFilter);
    else return;

    this.state.skip = 0;

    this.dataStateChange(this.state as DataStateChangeEvent);
  }

  public onType() {
    if (!this.columnSearchFilterTimeout)
      clearTimeout(this.columnSearchFilterTimeout);
  }

  public getExcelData = (): ExcelExportData => {
    return this.excelExportFunction(this.selectedRows, this.gridData.data);
  };

  public triggerAction(
    actionType: string,
    detectedItemData: any,
    additionalEntities?: { [key: string]: any },
  ) {
    const actionRequest: GridTriggeredActionData = {
      actionType,
      detectedItemData,
      ...(additionalEntities ? { additionalEntities } : {}),
    };

    this.triggerActionEvent.emit(actionRequest);
  }

  private isValidDateFormat(dateString: string): boolean {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    return datePattern.test(dateString);
  }

  private getDriverList(): void {
    this._driversService
      .syncDriversList()
      .pipe(map((res: DriversListData) => res.data))
      .subscribe({
        next: (response: DriversList[]) => {
          this.driversList = response;
        },
        error: (error: HttpErrorResponse) => {},
        complete: () => {},
      });
  }

  private getRideList(driverId: bigint): void {
    if (driverId == BigInt(0)) {
      this._appComponent.loaderComponent.toggleLoaderVisibility();
      return;
    }
    this._ridesService
      .syncRidesListByDriver(driverId)
      .pipe(map((res: RidesListData) => res.data))
      .subscribe({
        next: (response: RideList[]) => {
          this.rideList = response;
          this._appComponent.loaderComponent.toggleLoaderVisibility();
        },
        error: (error: HttpErrorResponse) => {},
        complete: () => {},
      });
  }

  public Search(): void {
    this.triggerGridDataAPI();
  }
  @ViewChild('dropdownlistDriverName', { read: ElementRef, static: false })
  driverDll: ElementRef;
  @ViewChild('dropdownlistRideId', { read: ElementRef, static: false })
  rideDll: ElementRef;

  public Clear(): void {
    this.state = {
      skip: 0,
      take: 25,
      group: [],
      filter: { filters: [], logic: 'or' },
      sort: [],
    };
    this.searchQuery = '';
    this.selectedRows = [];
    this.formattedSelectedDate = undefined;
    this.driverId = undefined;
    this.rideId = undefined;
    this.driverDll.nativeElement.classList.remove('ng-dirty');
    this.rideDll.nativeElement.classList.remove('ng-dirty');
    this.disabelSearch=true;
    this.triggerGridDataAPI();
  }

  private triggerGridDataAPI(): void {
    this.triggerGetGridDataAPI.emit({
      searchAllText: this.searchQuery.trim(),
      pageState: this.state,
      dateOfService: this.formattedSelectedDate,
      driverId: this.driverId == 0 ? null : this.driverId,
      rideId: this.rideId == 0 ? null : this.rideId,
    });
  }

  public onDateChange(detectedDate: Date): void {
    if (detectedDate > this.currentDate) {
      this.formattedSelectedDate = new Date();
      return;
    }
    clearTimeout(this.columnSearchFilterTimeout);
    this.columnSearchFilterTimeout = setTimeout(() => {
      const date = detectedDate ? detectedDate.toLocaleDateString('en-CA') : '';
      const isSelectedDateValid = this.isValidDateFormat(date);

      if (isSelectedDateValid) {
        this.state.skip = 0;
        this.formattedSelectedDate = new Date(date);
      }
    }, 750);
  }

  public valueChangeDriverId(value: any) {
    if (value != null) {
      this.driverDll.nativeElement.classList.add('ng-dirty');
      this.driverId = value;
      this._appComponent.loaderComponent.toggleLoaderVisibility();
      this.getRideList(BigInt(value));
    }
  }

  public valueChangeRideId(value: any) {
    if (value != null) {
      this.rideDll.nativeElement.classList.add('ng-dirty');
      this.rideId = value;
      this.disabelSearch=false;
    }
  }

  public navigateToMap(item: Ride) {
    event?.preventDefault();
    this._rideNavigationService.onChangeData.emit(item);
  }
}
