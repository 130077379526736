import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { CoreMenuItem } from 'src/app/interfaces/core-menu-data/core-menu-data.interface';

@Component({
  selector: 'app-sid-bar-tabs',
  templateUrl: './sid-bar-tabs.component.html',
  styleUrls: ['./sid-bar-tabs.component.css'],
})
export class SidBarTabsComponent {
  @Input() menuItems: CoreMenuItem[] = [];
  @Input() name: string = '';
  @Input() width = '64px';
  @Input() showName = true;
  @Input() icon: string = '';
  @Input() iconClass: string = '';
  @Input() url: string = '';
  showMenu = false;

  constructor(private router: Router) {}

  toggleMenu() {
    if (this.showName) {
      this.showMenu = true;
    }
  }

  onHover() {
    this.showMenu = true;
  }

  gotoPage() {
    this.router.navigate([`/${this.url}`]);
  }

  onLeave() {
    this.showMenu = false;

    const data = this.menuItems.filter(
      menuItem => (menuItem.children?.length || 0) > 0,
    );

    data.forEach(item => {
      item.openTab = false;
    });
  }
}
